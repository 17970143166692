import {  Route, Routes  } from 'react-router-dom';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Landingpage from './Pages/Landingpage/Landingpage';
import Admin from './Admin/Admin';
import Navigation from './Pages/Nav/Navigation';
import Deductions from './Admin/Manage deductions/Deductions';
// import Deductions from './Admin/Manage deductions/Deductions';
// import Signup from './Pages/SignUp';
// import Login from './Pages/Login';
import SignUp from './Pages/SignUp/SignUp';
import Login from './Pages/Login/Login';
import Pricing from './Pages/Pricing/Pricing';
import SignupBusiness from './Pages/SignUpVarieties/SignupBusiness';
import SignupPersonal from './Pages/SignUpVarieties/SignupPersonal';
import SignupStarter from './Pages/SignUpVarieties/SignupStarter';
import Role from './Admin/Manage-role/Role';
import CrteCompny from './Admin/Create Company/CrteCompny';
// import Viewdeduction from './Admin/View deductions/Viewdeduction';
import '../src/Admin/Manage-role/ToggleSlidder.css'
import Staff from './Admin/ManageStaff/Staff';
import CreateNewStaff from './Admin/ManageStaff/CreateNewStaff';
import Allowances from './Admin/Manage Allowances/Allowances';
import AllowanceSpec from './Admin/Allowance specification/AllowanceSpec';
import AllowanceType from './Admin/Allowance type/AllowancType';
import SalaryStructure from './Admin/Manage Payroll/SalaryStructure';
import Viewdeduction from './Admin/View deductions/Viewdeduction';
import Department from './Admin/Manage-dept/Department';
import ManageUser from './Admin/Manage-user/ManageUser';
import ManageStep from './Admin/Manage-step/ManageStep';
import ManageLevel from './Admin/Manage-level/ManageLevel';
import EditProfile from './Admin/Edit Profile/EditProfile';
import ChangePassword from './Admin/Change password/ChangePassword';
import { InfoFooter } from './InfoFooter';
// import { InfoFooter } from './infoFooter';
// import { InfoFooter } from './InfoFooter';
import AdminOnb from './Admin/Admin/AdminOnb';
 
import ManageGrade from './Admin/Manage-grade/ManageGrade';
import ManageEmployee from './Admin/ManageStaff/ManageEmployee';
import Payroll from './Admin/Manage Payroll/Payroll';
import EditNewStaff from './Admin/ManageStaff/EditNewStaff';
import Payslip from './Admin/Payslip/Payslip';
import SetNewPassword from './Pages/Set New Password/SetNewPassword';
import ForgotPassword from './Pages/Forgot Password/Forgot_password';
import PaymentInstruction from './Admin/PaymentInstruction';
import Remitance from './Admin/Manage Remitance/Remitance';

// import Payslip from './Admin/Payslip/Payslip';
// import 'bootstrap/dist/css/bootstrap.min.css';



function App() {
  const location = useLocation();

  useEffect(() => {
    // Scroll to the top of the page when the route changes
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
   
      <Routes>
        <Route path='/'element={<Landingpage/>}/>
        <Route path='/landing_page'element={<Landingpage/>}/>
        <Route path='/admin'element={<Admin/>}/>
        <Route path='/role'element={<Role/>}/>
        <Route path='/department'element={<Department/>}/>
        <Route path='/manage_step'element={<ManageStep/>}/>
        <Route path='/manage_level'element={<ManageLevel/>}/>
        <Route path='/manage_user'element={<ManageUser/>}/>
        <Route path='/manage_employee'element={<Staff/>}/>
        <Route path='/deductions'element={<Deductions/>}/>
        <Route path='/view_deduction'element={<Viewdeduction/>}/>
        <Route path='/allowances'element={<Allowances/>}/>
        <Route path='/allowance_spec'element={<AllowanceSpec/>}/>
        <Route path='/staff'element={<Staff/>}/>
        <Route path='/create_new_staff'element={<CreateNewStaff/>}/>
        <Route path='/edit_new_staff'element={<EditNewStaff/>}/>
        <Route path='/grade'element={<ManageGrade/>}/>
        <Route path='/salary_structure'element={<SalaryStructure/>}/>
        <Route path='/payroll'element={<Payroll/>}/>
        <Route path='/info_footer'element={<InfoFooter/>}/>
        <Route path='/allowance_type'element={<AllowanceType/>}/>
        <Route path='/allowance_spec'element={<AllowanceSpec/>}/>
        <Route path='/allowances'element={<Allowances/>}/>
        <Route path='/create_company'element={<CrteCompny/>}/>
        <Route path='/allowance_type'element={<AllowanceType/>}/>
        <Route path='/admin_onboarding'element={<AdminOnb/>}/>
        <Route path='/signup'element={<SignUp/>}/>
        <Route path='/login'element={<Login/>}/>
        <Route path='/pricing'element={<Pricing/>}/>
        <Route path='/signup_business'element={<SignupBusiness/>}/>
        <Route path='/signup_personal'element={<SignupPersonal/>}/>
        <Route path='/signup_starter'element={<SignupStarter/>}/>
        <Route path='/payslip'element={<Payslip/>}/>
        <Route path='/forgot_password'element={<ForgotPassword/>}/>
        <Route path='/set_new_password'element={<SetNewPassword/>}/>
        <Route path='/payment_instruction'element={<PaymentInstruction/>}/>
        <Route path='/remitance'element={<Remitance/>}/>

        <Route path='/EditProfile'element={<EditProfile/>}/>
        <Route path='/ChangePassword'element={<ChangePassword/>}/>
        {/* <Route path='/Success'element={<Success/>}/> */}
        {/* <Route path='/Failed'element={<Failed/>}/> */}
        {/* <Route path='/Choose'element={<Choose/>}/> */}
      </Routes>
 
    </>
  );
}
export default App;