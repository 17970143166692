import React, { useState, useEffect } from 'react';
import Happiness from '../../src/Images/happiness.svg';
import "./assets/plugins/bootstrap/css/bootstrap.min.css";
import "./assets/plugins/metisMenu/metisMenu.min.css";
import "./assets/plugins/fontawesome/css/all.min.css";
import "./assets/plugins/typicons/src/typicons.min.css";
import "./assets/plugins/themify-icons/themify-icons.min.css";
import  happiness from "./assets/dist/img/happiness.svg";
import "./assets/plugins/datatables/dataTables.bootstrap4.min.css";
import "./style.css";
import { AdminHeaderNav } from './AdminHeaderNav';
import { NavLink } from 'react-router-dom';
import Footer from '../Pages/Footer/Footer';
import { InfoFooter } from '../InfoFooter';
import { Button, Modal, Form, Spinner } from 'react-bootstrap';
import classes from './Admin.module.css';
import favicon from '../Images/faviconn.png'
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';






function Admin() {
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [bearer, setBearer] = useState('');
    const [selectedCompanyId, setSelectedCompanyId] = useState('');
    const [selectedCompanyName, setSelectedCompanyName] = useState('');

    const readData = async () => {
        try {
          const value = await AsyncStorage.getItem('userToken');
          const value1 = await AsyncStorage.getItem('selectedCompanyId');
          const value2 = await AsyncStorage.getItem('selectedCompanyName');
          
      
          if (value !== null) {
            setBearer(value);
            // setAuthenticated(true);
          }
          if (value1 !== null) {
            setSelectedCompanyId(value1);
            // console.log(value1, "DASHBOARD");
          }

          if (value2 !== null) {
            setSelectedCompanyName(value2);
            // console.log(value1, "DASHBOARD");
          }
         
        } catch (e) {
          alert('Failed to fetch the input from storage');
        }
      };
      
      useEffect(() => {
        readData();
      }, []);

    return (

        <div className="fixed">


            {/* <!-- #END# Page Loader --> */}
            <div className="wrapper">

                {/* <!-- Sidebar  --> */}


                {/* <!-- Page Content  --> */}
                <div className="content-wrapper">
                    <div className="main-content">
                        <AdminHeaderNav />

                        {/* <!--Content Header (Page header)--> */}
                        <div className="content-header row align-items-center m-0">
                            
                            <div className="col-sm-8 header-title p-0">
                                <div className="media">
                                <div className="header-icon text-success mr-3"><i className=""><img src={favicon} className={classes.favshi} alt="favicon" /></i></div>
                                    <div className="media-body">
                                        <h1 className="font-weight-bold">Welcome, {selectedCompanyName} </h1>
                                        <small>From now on you will start your activities.</small>
                                    </div>
                                    {/* <div
                                        style={{
                                            marginTop: 20,
                                            marginBottom: 20,
                                            justifyContent: "flex-end",
                                            display: "flex",
                                            marginLeft: "auto",
                                        }}
                                    >
                                        <Button variant="success" onClick={handleShow}>
                                            Create New Company
                                        </Button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        {/* <Modal size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered show={show} animation={false}>
                            <Modal.Header closeButton>
                                <Modal.Title>Create New Company</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form style={{ marginTop: 20 }}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Name of Company</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Company Name"
                                            // autoFocus
                                            value={companyName}
                                            onChange={(e) => setCompanyName(e.target.value)}
                                        />
                                        <div style={{ marginTop: 10 }} />
                                        <Form.Label>Email Address</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Email Address"
                                            // autoFocus
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <div style={{ marginTop: 10 }} />
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Phone Number"
                                            // autoFocus
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                        />

                                        <div style={{ marginTop: 10 }} />
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Address"
                                            // autoFocus
                                            value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                        />

                                    </Form.Group>
                                </Form>
                            </Modal.Body>






                            <Modal.Footer>
                                <Button variant="danger" onClick={handleClose}>
                                    Go back
                                </Button>
                                <Button variant="success" >
                                    {loading ? <Spinner id="loader" animation="border" variant="warning" /> : 'Save Changes'}
                                </Button>
                            </Modal.Footer>
                        </Modal> */}
                        {/* <!--/.Content Header (Page header)--> */}
                        <div className="body-content">


                        <div className="row">
                            <div className="col-lg-12 col-xl-6">
                                <div className="card mb-4">
                                    <div className="card-body text-center">
                                        <div className="row justify-content-center">
                                            <div className="greet-user col-12 col-xl-10">
                                                <img src={happiness} alt="happiness" className="img-fluid  mb-2"/>
                                                <h2 className="fs-23 font-weight-600 mb-2">
                                                    You are currently working on the payroll of {selectedCompanyName},
                                                </h2>
                                                <p className="text-muted">
                                                    You have done 57.6% more sales today. Check your new badge in your profile.
                                                </p>
                                                {/* <NavLink to={'#'}  className="btn btn-success">
                                                    Try it for free
                                                </NavLink> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-xl-6">
                                <div className="row">
                                    <div className="col-md-6 col-lg-6">
                                        
                                        <div className="p-2 bg-white rounded p-3 mb-3">
                                            <div className="header-pretitle text-muted fs-11 font-weight-bold text-uppercase mb-2">
                                                Revenue today
                                            </div>
                                            <div className="badge badge-success fs-26 text-monospace mx-auto">$98<span className="opacity-50 small">.50</span></div>
                                            <div className="text-muted small mt-1">
                                                <span className="text-danger">
                                                    <i className="fas fa fa-long-arrow-alt-down"></i>
                                                    5%
                                                </span> vs average
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6">
                                        {/* <!--Feedback--> */}
                                        <div className="d-flex position-relative overflow-hidden flex-column p-3 mb-3 bg-white rounded">
                                            <div className="header-pretitle text-muted fs-11 font-weight-bold text-uppercase mb-2">Latest Feedback</div>
                                            <i className="fas fa fa-smile opacity-25 fa-5x text-warning decorative-icon"></i>
                                            <div className="d-flex">
                                                <div>
                                                    {/* <img src={} alt='' className="d-block rounded-circle" width="32" /> */}
                                                </div>
                                                <div className="pl-3">
                                                    Nothing bad to say<br/> at this point 😌
                                                    <NavLink to={'#'} className="d-block mt-1 small opacity-75">  Write back </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6">
                                        {/* <!--Balance indicator--> */}
                                        <div className="p-2 bg-white rounded p-3 mb-3">
                                            <div className="header-pretitle text-muted fs-11 font-weight-bold text-uppercase mb-2">
                                                Balance
                                            </div>
                                            <div className="text-muted">
                                                <i className="fas fa fa-long-arrow-alt-up text-success"></i>
                                                <span className="text-success text-size-new2 text-monospace">
                                                    0.02%
                                                </span> this month
                                            </div>
                                            <span className="small">Stripe:</span>
                                            <span className="small text-monospace mx-auto">$4,480<span className="opacity-50">.00</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6">
                                        {/* <!--Time on site indicator--> */}
                                        <div className="d-flex flex-column p-3 mb-3 bg-white rounded">
                                            <div className="header-pretitle text-muted fs-11 font-weight-bold text-uppercase mb-2">avg time on site</div>
                                            <div className="d-flex align-items-center">
                                                <i className="fas fa fa-clock opacity-25 mr-2 text-size-3"></i>
                                                <span className="text-size-2 text-monospace">10</span>
                                                <span className="text-size-2">m</span>
                                                <span className="text-size-2 text-monospace">30</span>
                                                <span className="text-size-2">s</span>
                                            </div>
                                            <div className="text-muted small">
                                                <span className="text-success text-monospace">
                                                    <i className="fas fa fa-long-arrow-alt-up"></i>
                                                    5%
                                                </span> vs last week
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6">
                                        {/* <!--Top Referrals--> */}
                                        <div className="d-flex flex-column p-3 mb-3 bg-white rounded">
                                            <div className="header-pretitle text-muted fs-11 font-weight-bold text-uppercase mb-2">Top Referrals</div>
                                            <div>
                                                <i className="fas fa fa-caret-up text-success"></i>
                                                <span className="mx-1 text-monospace">62%</span>
                                                {/* <!--           <i className="fab fa-xs fa-google"></i>  --> */}
                                                <NavLink to={'#'}>  google</NavLink>
                                            </div>
                                            <div className="opacity-75">
                                                <i className="fas fa fa-caret-down text-danger"></i>
                                                <span className="text-secondary mx-1 text-monospace">25%</span>
                                                {/* <!--           <i className="fab fa-xs fa-y-combinator"></i>  --> */}
                                                <NavLink to={'#'}> news.ycombinator</NavLink>
                                            </div>
                                            <div className="opacity-50">
                                                <i className="text-muted">–</i>
                                                <span className="text-secondary mx-1 text-monospace">20%</span>
                                                {/* <!--           <i className="fab fa-xs fa-product-hunt"></i>  --> */}
                                                <NavLink to={'#'}> producthunt</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6">
                                    <div className="d-flex flex-column p-3 mb-3 bg-white rounded">
                                            <div className="header-pretitle text-muted fs-11 font-weight-bold text-uppercase mb-2">Top Referrals</div>
                                            <div>
                                                <i className="fas fa fa-caret-up text-success"></i>
                                                <span className="mx-1 text-monospace">62%</span>
                                                {/* <!--           <i className="fab fa-xs fa-google"></i>  --> */}
                                                <NavLink to={'#'}>  google</NavLink>
                                            </div>
                                            <div className="opacity-75">
                                                <i className="fas fa fa-caret-down text-danger"></i>
                                                <span className="text-secondary mx-1 text-monospace">25%</span>
                                                {/* <!--           <i className="fab fa-xs fa-y-combinator"></i>  --> */}
                                                <NavLink to={'#'}> news.ycombinator</NavLink>
                                            </div>
                                            <div className="opacity-50">
                                                <i className="text-muted">–</i>
                                                <span className="text-secondary mx-1 text-monospace">20%</span>
                                                {/* <!--           <i className="fab fa-xs fa-product-hunt"></i>  --> */}
                                                <NavLink to={'#'}> producthunt</NavLink>
                                            </div>
                                        </div>
                                        {/* <!--Sessions by device--> */}
                                        {/* <div className="d-flex flex-column p-3 mb-3 bg-white rounded">
                                            <div className="header-pretitle text-muted fs-11 font-weight-bold text-uppercase mb-2">Sessions by device</div>
                                            <div className="row text-center" style={{display:"flex",}}>
                                                <div className="col">
                                                    <i className="fas fa fa-mobile mb-2 text-size-2"></i>
                                                    <div className="text-monospace">54%</div>
                                                </div>
                                                <div className="col">
                                                    <i className="fas fa fa-tablet opacity-50 mb-2 text-size-2"></i>
                                                    <div className="text-monospace text-secondary">26%</div>
                                                </div>
                                                <div className="col">
                                                    <i className="fas fa fa-laptop opacity-25 mb-2 text-size-2"></i>
                                                    <div className="text-monospace text-secondary">20%</div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>


                            {/* <div className="row">

                                <div className="col-lg-12 ">
                                    <div className="row">
                                        <div className="col-md-3 col-lg-3">

                                            <div className="p-2 bg-white rounded p-3 mb-3 shadow-sm">
                                                <div className="header-pretitle text-muted fs-11 font-weight-bold text-uppercase mb-2">
                                                    Users
                                                </div>
                                                <div className="badge badge-success fs-26 text-monospace mx-auto">$98<span class="opacity-50 small">.50</span></div>
                                                <div className="text-muted small mt-1">
                                                    <span className="text-danger">
                                                        <i className="fas fa fa-long-arrow-alt-down"></i>
                                                        5%
                                                    </span> vs average
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-lg-3">
                                            
                                            <div className="p-2 bg-white rounded p-3 mb-3 shadow-sm">
                                                <div className="header-pretitle text-muted fs-11 font-weight-bold text-uppercase mb-2">
                                                    Staff
                                                </div>
                                                <div className="badge badge-success fs-26 text-monospace mx-auto">$98<span class="opacity-50 small">.50</span></div>
                                                <div className="text-muted small mt-1">
                                                    <span className="text-danger">
                                                        <i className="fas fa fa-long-arrow-alt-down"></i>
                                                        5%
                                                    </span> vs average
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-lg-3">
                                            
                                            <div className="p-2 bg-white rounded p-3 mb-3 shadow-sm">
                                                <div className="header-pretitle text-muted fs-11 font-weight-bold text-uppercase mb-2">
                                                    Staff
                                                </div>
                                                <div className="badge badge-success fs-26 text-monospace mx-auto">$98<span class="opacity-50 small">.50</span></div>
                                                <div className="text-muted small mt-1">
                                                    <span className="text-danger">
                                                        <i className="fas fa fa-long-arrow-alt-down"></i>
                                                        5%
                                                    </span> vs average
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-lg-3">
                                            
                                            <div className="p-2 bg-white rounded p-3 mb-3 shadow-sm">
                                                <div className="header-pretitle text-muted fs-11 font-weight-bold text-uppercase mb-2">
                                                    Staff
                                                </div>
                                                <div className="badge badge-success fs-26 text-monospace mx-auto">$98<span class="opacity-50 small">.50</span></div>
                                                <div className="text-muted small mt-1">
                                                    <span className="text-danger">
                                                        <i className="fas fa fa-long-arrow-alt-down"></i>
                                                        5%
                                                    </span> vs average
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-lg-3">
                                            
                                            <div className="d-flex flex-column p-3 mb-3 bg-white shadow-sm rounded">
                                                <div className="header-pretitle text-muted fs-11 font-weight-bold text-uppercase mb-2">avg time on site</div>
                                                <div className="d-flex align-items-center">
                                                    <i className="fas fa fa-clock opacity-25 mr-2 text-size-3"></i>
                                                    <span className="text-size-2 text-monospace">10</span>
                                                    <span className="text-size-2">m</span>
                                                    <span className="text-size-2 text-monospace">30</span>
                                                    <span className="text-size-2">s</span>
                                                </div>
                                                <div className="text-muted small">
                                                    <span className="text-success text-monospace">
                                                        <i className="fas fa fa-long-arrow-alt-up"></i>
                                                        5%
                                                    </span> vs last week
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-lg-3">
                                            
                                            <div className="d-flex flex-column p-3 mb-3 bg-white shadow-sm rounded">
                                                <div className="header-pretitle text-muted fs-11 font-weight-bold text-uppercase mb-2">avg time on site</div>
                                                <div className="d-flex align-items-center">
                                                    <i className="fas fa fa-clock opacity-25 mr-2 text-size-3"></i>
                                                    <span className="text-size-2 text-monospace">10</span>
                                                    <span className="text-size-2">m</span>
                                                    <span className="text-size-2 text-monospace">30</span>
                                                    <span className="text-size-2">s</span>
                                                </div>
                                                <div className="text-muted small">
                                                    <span className="text-success text-monospace">
                                                        <i className="fas fa fa-long-arrow-alt-up"></i>
                                                        5%
                                                    </span> vs last week
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-lg-3">
                                            
                                            <div className="d-flex flex-column p-3 mb-3 bg-white shadow-sm rounded">
                                                <div className="header-pretitle text-muted fs-11 font-weight-bold text-uppercase mb-2">avg time on site</div>
                                                <div className="d-flex align-items-center">
                                                    <i className="fas fa fa-clock opacity-25 mr-2 text-size-3"></i>
                                                    <span className="text-size-2 text-monospace">10</span>
                                                    <span className="text-size-2">m</span>
                                                    <span className="text-size-2 text-monospace">30</span>
                                                    <span className="text-size-2">s</span>
                                                </div>
                                                <div className="text-muted small">
                                                    <span className="text-success text-monospace">
                                                        <i className="fas fa fa-long-arrow-alt-up"></i>
                                                        5%
                                                    </span> vs last week
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-lg-3">
                                            
                                            <div className="d-flex flex-column p-3 mb-3 bg-white shadow-sm rounded">
                                                <div className="header-pretitle text-muted fs-11 font-weight-bold text-uppercase mb-2">avg time on site</div>
                                                <div className="d-flex align-items-center">
                                                    <i className="fas fa fa-clock opacity-25 mr-2 text-size-3"></i>
                                                    <span className="text-size-2 text-monospace">10</span>
                                                    <span className="text-size-2">m</span>
                                                    <span className="text-size-2 text-monospace">30</span>
                                                    <span className="text-size-2">s</span>
                                                </div>
                                                <div className="text-muted small">
                                                    <span className="text-success text-monospace">
                                                        <i className="fas fa fa-long-arrow-alt-up"></i>
                                                        5%
                                                    </span> vs last week
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div> */}
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h6 className="fs-17 font-weight-600 mb-0">All Employees</h6>
                                                </div>
                                                {/* <div className="text-right">
                                                <div className="actions">
                                                    <NavLink href="#" className="action-item"><i className="ti-reload"></i></NavLink>
                                                    <div className="dropdown action-item" data-toggle="dropdown">
                                                        <NavLink href="#" className="action-item"><i className="ti-more-alt"></i></NavLink>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                            <NavLink href="#" className="dropdown-item">Refresh</NavLink>
                                                            <NavLink href="#" className="dropdown-item">Manage Widgets</NavLink>
                                                            <NavLink href="#" className="dropdown-item">Settings</NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            </div>
                                        </div>
                                        <div className={classes.belowheader}>
                                            <div className={classes.greenbtn}>
                                                <div>
                                                    <button>Copy</button>
                                                    <button>Excel</button>
                                                    <button>PDF</button>
                                                    <button className={classes.diffbtn}>Column visibility</button>
                                                </div>
                                            </div>
                                            <div className={classes.searchside}>
                                                <label className={classes.onlylabel}>Search:</label>
                                                <input className={classes.thesearch} type="search" />
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-resposive">


                                                <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Image</th>
                                                            <th>Name</th>
                                                            <th>Phone</th>
                                                            <th>Street Address</th>
                                                            <th>% Share</th>
                                                            <th>City</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className="avatar-group">
                                                                    <a href="user_profile.html" className="avatar avatar-xs" data-toggle="tooltip" title="Melissa Ayre">
                                                                        <img src="assets/dist/img/avatar-1.jpg" className="avatar-img rounded-circle" alt="..." />
                                                                    </a>
                                                                    <a href="user_profile.html" className="avatar avatar-xs" data-toggle="tooltip" title="Karen Robinson">
                                                                        <img src="assets/dist/img/avatar-2.jpg" className="avatar-img rounded-circle" alt="..." />
                                                                    </a>
                                                                    <a href="user_profile.html" className="avatar avatar-xs" data-toggle="tooltip" title="Miyah Myles">
                                                                        <img src="assets/dist/img/avatar-3.jpg" className="avatar-img rounded-circle" alt="..." />
                                                                    </a>
                                                                    <a href="user_profile.html" className="avatar avatar-xs" data-toggle="tooltip" title="Daniela Dewitt">
                                                                        <img src="assets/dist/img/avatar-4.jpg" className="avatar-img rounded-circle" alt="..." />
                                                                    </a>
                                                                </div>
                                                            </td>
                                                            <td>Naeem Khan</td>
                                                            <td>123 456 7890</td>
                                                            <td>294-318 Duis Ave</td>
                                                            <td>
                                                                <div className="sparkline1"></div>
                                                            </td>
                                                            <td>Noakhali</td>
                                                            <td>
                                                                <a href="#" className="btn btn-success-soft btn-sm mr-1"><i className="far fa-eye"></i></a>
                                                                <a href="#" className="btn btn-danger-soft btn-sm"><i className="far fa-trash-alt"></i></a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="avatar-group">
                                                                    <a href="user_profile.html" className="avatar avatar-xs" data-toggle="tooltip" title="Miyah Myles">
                                                                        <img src="assets/dist/img/avatar-3.jpg" className="avatar-img rounded-circle" alt="..." />
                                                                    </a>
                                                                    <a href="user_profile.html" className="avatar avatar-xs" data-toggle="tooltip" title="Daniela Dewitt">
                                                                        <img src="assets/dist/img/avatar-2.jpg" className="avatar-img rounded-circle" alt="..." />
                                                                    </a>
                                                                </div>
                                                            </td>
                                                            <td>Tuhin Sarkar</td>
                                                            <td>123 456 7890</td>
                                                            <td>680-1097 Mi Rd.</td>
                                                            <td>
                                                                <div className="sparkline2"></div>
                                                            </td>
                                                            <td>Lavoir</td>
                                                            <td>
                                                                <a href="#" className="btn btn-success-soft btn-sm mr-1"><i className="far fa-eye"></i></a>
                                                                <a href="#" className="btn btn-danger-soft btn-sm"><i className="far fa-trash-alt"></i></a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="avatar-group">
                                                                    <a href="user_profile.html" className="avatar avatar-xs" data-toggle="tooltip" title="Karen Robinson">
                                                                        <img src="assets/dist/img/avatar-2.jpg" className="avatar-img rounded-circle" alt="..." />
                                                                    </a>
                                                                    <a href="user_profile.html" className="avatar avatar-xs" data-toggle="tooltip" title="Miyah Myles">
                                                                        <img src="assets/dist/img/avatar-3.jpg" className="avatar-img rounded-circle" alt="..." />
                                                                    </a>
                                                                    <a href="user_profile.html" className="avatar avatar-xs" data-toggle="tooltip" title="Daniela Dewitt">
                                                                        <img src="assets/dist/img/avatar-4.jpg" className="avatar-img rounded-circle" alt="..." />
                                                                    </a>
                                                                </div>
                                                            </td>
                                                            <td>Tanjil Ahmed</td>
                                                            <td>456 789 1230</td>
                                                            <td>Ap #289-8161 In Avenue</td>
                                                            <td>
                                                                <div className="sparkline3"></div>
                                                            </td>
                                                            <td>Dhaka</td>
                                                            <td>
                                                                <a href="#" className="btn btn-success-soft btn-sm mr-1"><i className="far fa-eye"></i></a>
                                                                <a href="#" className="btn btn-danger-soft btn-sm"><i className="far fa-trash-alt"></i></a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="avatar-group">
                                                                    <a href="user_profile.html" className="avatar avatar-xs" data-toggle="tooltip" title="Melissa Ayre">
                                                                        <img src="assets/dist/img/avatar-1.jpg" className="avatar-img rounded-circle" alt="..." />
                                                                    </a>
                                                                    <a href="user_profile.html" className="avatar avatar-xs" data-toggle="tooltip" title="Karen Robinson">
                                                                        <img src="assets/dist/img/avatar-2.jpg" className="avatar-img rounded-circle" alt="..." />
                                                                    </a>
                                                                    <a href="user_profile.html" className="avatar avatar-xs" data-toggle="tooltip" title="Daniela Dewitt">
                                                                        <img src="assets/dist/img/avatar-4.jpg" className="avatar-img rounded-circle" alt="..." />
                                                                    </a>
                                                                </div>
                                                            </td>
                                                            <td>Sourav</td>
                                                            <td>789 123 4560</td>
                                                            <td>226-4861 Augue. St.</td>
                                                            <td>
                                                                <div className="sparkline4"></div>
                                                            </td>
                                                            <td>Rongpur</td>
                                                            <td>
                                                                <a href="#" className="btn btn-success-soft btn-sm mr-1"><i className="far fa-eye"></i></a>
                                                                <a href="#" className="btn btn-danger-soft btn-sm"><i className="far fa-trash-alt"></i></a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="avatar-group">
                                                                    <a href="user_profile.html" className="avatar avatar-xs" data-toggle="tooltip" title="Melissa Ayre">
                                                                        <img src="assets/dist/img/avatar-1.jpg" className="avatar-img rounded-circle" alt="..." />
                                                                    </a>
                                                                    <a href="user_profile.html" className="avatar avatar-xs" data-toggle="tooltip" title="Karen Robinson">
                                                                        <img src="assets/dist/img/avatar-2.jpg" className="avatar-img rounded-circle" alt="..." />
                                                                    </a>
                                                                    <a href="user_profile.html" className="avatar avatar-xs" data-toggle="tooltip" title="Miyah Myles">
                                                                        <img src="assets/dist/img/avatar-3.jpg" className="avatar-img rounded-circle" alt="..." />
                                                                    </a>
                                                                    <a href="user_profile.html" className="avatar avatar-xs" data-toggle="tooltip" title="Daniela Dewitt">
                                                                        <img src="assets/dist/img/avatar-4.jpg" className="avatar-img rounded-circle" alt="..." />
                                                                    </a>
                                                                </div>
                                                            </td>
                                                            <td>Jahangir Alam</td>
                                                            <td>(01662) 59083</td>
                                                            <td>3219 Elit Avenue</td>
                                                            <td>
                                                                <div className="sparkline5"></div>
                                                            </td>
                                                            <td>Chittagong</td>
                                                            <td>
                                                                <a href="#" className="btn btn-success-soft btn-sm mr-1"><i className="far fa-eye"></i></a>
                                                                <a href="#" className="btn btn-danger-soft btn-sm"><i className="far fa-trash-alt"></i></a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="avatar-group">
                                                                    <a href="user_profile.html" className="avatar avatar-xs" data-toggle="tooltip" title="Melissa Ayre">
                                                                        <img src="assets/dist/img/avatar-1.jpg" className="avatar-img rounded-circle" alt="..." />
                                                                    </a>
                                                                    <a href="user_profile.html" className="avatar avatar-xs" data-toggle="tooltip" title="Karen Robinson">
                                                                        <img src="assets/dist/img/avatar-2.jpg" className="avatar-img rounded-circle" alt="..." />
                                                                    </a>
                                                                    <a href="user_profile.html" className="avatar avatar-xs" data-toggle="tooltip" title="Daniela Dewitt">
                                                                        <img src="assets/dist/img/avatar-5.jpg" className="avatar-img rounded-circle" alt="..." />
                                                                    </a>
                                                                </div>
                                                            </td>
                                                            <td>Brielle Williamson</td>
                                                            <td>123 456 7890</td>
                                                            <td>Dhaka-1229, Bangladesh</td>
                                                            <td>
                                                                <div className="sparkline6"></div>
                                                            </td>
                                                            <td>Barisal</td>
                                                            <td>
                                                                <a href="#" className="btn btn-success-soft btn-sm mr-1"><i className="far fa-eye"></i></a>
                                                                <a href="#" className="btn btn-danger-soft btn-sm"><i className="far fa-trash-alt"></i></a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--/.body content--> */}
                    </div>
                    {/* <!--/.main content--> */}
                    {/* <Footer /> */}
                    <InfoFooter />
                    {/* <footer className="footer-content">
                        <div className="footer-text d-flex align-items-center justify-content-between">
                            <div className="copy">© 2018 Bdtask Responsive Bootstrap 4 Dashboard Template</div>
                            <div className="credit">Designed by: <a href="#">Bdtask</a></div>
                        </div>
                    </footer> */}
                    {/* <!--/.footer content--> */}
                    <div className="overlay"></div>
                </div>
                {/* <!--/.wrapper--> */}
            </div>



        </div>

    );
}

export default Admin;