import React, { useState, useEffect } from 'react';
import "../assets/plugins/bootstrap/css/bootstrap.min.css";
import "../assets/plugins/metisMenu/metisMenu.min.css";
import "../assets/plugins/fontawesome/css/all.min.css";
import "../assets/plugins/typicons/src/typicons.min.css";
import "../assets/plugins/themify-icons/themify-icons.min.css";
import "../assets/plugins/datatables/dataTables.bootstrap4.min.css";
import { AdminHeaderNav } from '../AdminHeaderNav';
// import Footer from '../../Pages/Footer/Footer';
import { InfoFooter } from '../../InfoFooter';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import { NavLink, useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';

function CreateNewStaff() {
    const [title, setTitle] = useState('');
    const [tin, setTin] = useState('');
    const [surname, setSurname] = useState('');
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [accountNo, setAccountNo] = useState('');
    const [bank, setBank] = useState('');
    const [medical, setMedical] = useState('');
    const [staffId, setStaffId] = useState('');
    const [rsa, setRsa] = useState('');
    const [city, setCity] = useState('');
    const [address, setAddress] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedResignDate, setSelectedResignDate] = useState('');
    const [selectedEmployDate, setSelectedEmployDate] = useState('');
    const [selectedGender, setSelectedGender] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedStep, setSelectedStep] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedGrade, setSelectedGrade] = useState('');
    const [state, setState] = useState([]);
    const [countries, setCountries] = useState([]);
    const [selectedState, setSelectedState] = useState('');
    const [lga, setLga] = useState([]);
    const [selectedLga, setSelectedLga] = useState('');
    const [selectedLevel, setSelectedLevel] = useState('');
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [selectedCertificate, setSelectedCertificate] = useState('');
    const [tableData, setTableData] = useState([]);
    const [tableData1, setTableData1] = useState([]);
    const [tableData2, setTableData2] = useState([]);
    const [tableData3, setTableData3] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [bearer, setBearer] = useState('');
    const [netpay, setNetpay] = useState(100);
    const navigate = useNavigate();
    const [file, setFile] = useState();
    const [selectedCompanyId, setSelectedCompanyId] = useState('');


    const handleChange = (e) => {
        const selectedFile = e.target.files[0];

        if (selectedFile) {
            const allowedExtensions = ['jpeg', 'jpg', 'png', 'gif', 'svg'];
            const fileExtension = selectedFile.name.split('.').pop().toLowerCase();

            if (allowedExtensions.includes(fileExtension)) {
                setFile(selectedFile);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Invalid File Type',
                    text: 'Please select a file of type: jpeg, png, jpg, gif, svg.',
                });

                // Clear the file input and prevent default behavior
                e.target.value = null;
                e.preventDefault();
            }
        }
    };



    const handleDateChange = (event) => {
        const inputDate = event.target.value;

        const formattedDate = inputDate.split('-').slice(0, 3).join('-');
    setSelectedDate(formattedDate);
    };

    const handleEmployDateChange = (event) => {
        setSelectedEmployDate(event.target.value);
    };

    const handleResignDateChange = (event) => {
        setSelectedResignDate(event.target.value);
    };

    const handleGenderChange = (event) => {
        setSelectedGender(event.target.value);
    };

    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
    };

    // const handleCountryChange = (event) => {
    //     setSelectedCountry(event.target.value);
    // };

    const handleCertificateChange = (event) => {
        setSelectedCertificate(event.target.value);
    };

    const handleStepChange = (event) => {
        setSelectedStep(event.target.value);
    };

    const handleGradeChange = (event) => {
        setSelectedGrade(event.target.value);
    };

    const handleLevelChange = (event) => {
        setSelectedLevel(event.target.value);
    };

    const handleDepartmentChange = (event) => {
        setSelectedDepartment(event.target.value);
    };

    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('userToken');
            const value1 = await AsyncStorage.getItem('selectedCompanyId');

            if (value !== null) {
                setBearer(value);
                // setAuthenticated(true);

                if (value1 !== null) {
                    setSelectedCompanyId(value1);
                    // console.log(value1, "DASHBOARD");
                }
            }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();
    }, []);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
    };

    const goBack = () => {
        navigate(-1);
    }


    const fetchStep = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get('https://payroll.patna.ng/api/admin/step', {
                params: { company_id: selectedCompanyId },
                headers: headers,
            });
            const results = response.data?.Steps?.data;
            setTableData(results);
            // console.log(results, "STEP");
        } catch (error) {
            const errorStatus = error.response?.data?.message;
            console.log(errorStatus);
            setTableData([]);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchGrades = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get('https://payroll.patna.ng/api/admin/grade', {
                params: { company_id: selectedCompanyId },
                headers: headers,
            });
            const results = response.data?.data?.data;
            setTableData1(results);
            // console.log(results);
        } catch (error) {
            const errorStatus = error.response?.data?.message;
            console.log(errorStatus);
            setTableData1([]);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchLevel = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get('https://payroll.patna.ng/api/admin/level', {
                params: { company_id: selectedCompanyId },
                headers: headers,
            });
            const results = response.data?.data?.data;
            setTableData2(results);
            // console.log(results);
        } catch (error) {
            const errorStatus = error.response?.data?.message;
            console.log(errorStatus);
            setTableData2([]);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchDepartment = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get('https://payroll.patna.ng/api/admin/department', {
                params: { company_id: selectedCompanyId },
                headers: headers,
            });
            const results = response.data?.data?.data;
            setTableData3(results);
        } catch (error) {
            const errorStatus = error.response?.data?.message;
            console.log(errorStatus);
            setTableData([]);
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        if (bearer) {
            fetchStep();
            fetchGrades();
            fetchLevel();
            fetchDepartment();

        }
    }, [bearer]);

    const createStaff = async () => {
        setLoading(true);
      
        try {
          const formData = new FormData();
      
          formData.append("title", title);
          formData.append("lastname", surname);
          formData.append("middlename", middleName);
          formData.append("firstname", firstName);
          formData.append("dob", selectedDate);
          formData.append("gender", selectedGender);
          formData.append("marital_status", selectedStatus);
          formData.append("phone_number", phone);
          formData.append("email", email);
          formData.append("staff_id", staffId);
          formData.append("rsa_number", rsa);
          formData.append("qualification", selectedCertificate);
          formData.append("step", selectedStep);
          formData.append("grade", selectedGrade);
          formData.append("level", selectedLevel);
          formData.append("dept_id", selectedDepartment);
          formData.append("country", selectedCountry);
          formData.append("address", address);
          formData.append("city", city);
          formData.append("employment_date", selectedEmployDate);
          formData.append("account_number", accountNo);
          formData.append("account_bank", bank);
          formData.append("medical_condition", medical);
          formData.append("company_id", selectedCompanyId);
          formData.append("tax_identification_number", tin);
          formData.append("staff_image", file);
          formData.append("state", selectedState);
          formData.append("lga", selectedLga);
      
          const response = await axios.post(
            "https://payroll.patna.ng/api/admin/staff/create",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${bearer}`
              },
            }
          );
      
          navigate('/staff');
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
          });
          console.log(response.data);
        } catch (error) {
          const errorStatus = error.response;
          Swal.fire({
            icon: 'error',
            title: 'Failed',
            text: JSON.stringify(errorStatus),
          });
          console.error(errorStatus);
        } finally {
          setLoading(false);
        }
      };
      

    const fetchCountries = async () => {
        try {
          const response = await axios.get('https://payroll.patna.ng/api/countries', {
            headers: headers,
          });
          const countryResults = response.data?.data;
          setCountries(countryResults);
        } catch (error) {
          console.error('Error fetching countries:', error);
          setCountries([]);
        }
      };
    
      const fetchStatesByCountry = async (countryId) => {
        try {
          const response = await axios.get(`https://payroll.patna.ng/api/states?country_id=${countryId}`, {
            headers: headers,
          });
          const stateResults = response.data?.data;
          setState(stateResults);
        } catch (error) {
          console.error('Error fetching states:', error);
          setState([]);
        }
      };
    
      const fetchLgasByState = async (stateId) => {
        try {
          const response = await axios.get(`https://payroll.patna.ng/api/local_govt?state_id=${stateId}`, {
            headers: headers,
          });
          const lgaResults = response.data?.data;
          setLga(lgaResults);
        } catch (error) {
          console.error('Error fetching LGAs:', error);
          setLga([]);
        }
      };
    
      useEffect(() => {
        const fetchData = async () => {
          await fetchCountries();
    
          // Assuming Nigeria has the ID '156'
          const nigeriaCountry = countries.find(country => country.id === '156');
    
          if (nigeriaCountry) {
            setSelectedCountry('156');
            await fetchStatesByCountry('156');
    
            // Automatically select the first state (you can customize this logic)
            if (state.length > 0) {
              setSelectedState(state[0].id_no);
              await fetchLgasByState(state[0].id_no);
            }
          }
        };
    
        fetchData();
      }, []); // Empty dependency array to ensure it runs only once on mount
    
      const handleCountryChange = async (event) => {
        const newCountryId = event.target.value;
        setSelectedCountry(newCountryId);
    
        if (newCountryId === '156') { // Assuming '156' is the ID for Nigeria
          await fetchStatesByCountry(newCountryId);
    
          // Automatically select the first state (you can customize this logic)
          if (state.length > 0) {
            setSelectedState(state[0].id_no);
            await fetchLgasByState(state[0].id_no);
          }
        } else {
          setState([]);
          setLga([]);
        }
      };


    const handleStateChange = (event) => {
        const newStateId = event.target.value;
        setSelectedState(newStateId);


        if (newStateId) {
            fetchLgasByState(newStateId);
        } else {
            setLga([]);
        }
    };


    return (
        <div>



            <div className="main-content">
                <AdminHeaderNav />

                <div className="content-header row align-items-center m-0">

                    <div className="col-sm-8 header-title p-0">
                        <div className="media">
                            <div className="header-icon text-success mr-3"><i className="typcn typcn-spiral"></i></div>
                            <div className="media-body">
                                <h1 className="font-weight-bold">Create a new employee </h1>
                                <small>Fill the respective fields to complete your registration....</small>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div className="body-content">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="create-new-staff-card-header">
                            <div className="d-flex justify-content-between align-items-center">
                                {/* <div>
                                    <h6 className="fs-17 font-weight-600 mb-0 textInput">All Staff</h6>
                                </div> */}
                                {/* <div className="text-right modal-effect ">
                                    <a href="window.history.back();" className="btn btn-success rounded-pill w-100p btn-sm mr-1">
                                        <i className="fas fa-plus"></i> Back
                                    </a>
                                </div> */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="card-body">
                                            <div style={{ marginBottom: 30 }}>
                                                <Button variant='success' onClick={goBack}><i className="fa-solid fa-arrow-left"></i> Go Back</Button>
                                            </div>
                                            <h4>Section A:</h4>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Title</label>
                                                        <div className="col-sm-9">
                                                            <input className="form-control" required="" type="text" value={title} onChange={(e) => setTitle(e.target.value)} name="title" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Surname</label>
                                                        <div className="col-sm-9">
                                                            <input className="form-control" required="" type="text" value={surname} onChange={(e) => setSurname(e.target.value)} name="lastname" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">First Name</label>
                                                        <div className="col-sm-9">
                                                            <input className="form-control" required="" type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} name="firstname" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Middle Name</label>
                                                        <div className="col-sm-9">
                                                            <input className="form-control" required="" type="text" value={middleName} onChange={(e) => setMiddleName(e.target.value)} name="middlename" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
      <div className="form-group row">
        <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">
          Date of Birth
        </label>
        <div className="col-sm-9">
          <input
            className="form-control"
            required=""
            type="date" // Change type to text
            onChange={handleDateChange}
            name="dob"
            value={selectedDate}
            placeholder="YY-MM-DD"
          />
        </div>
      </div>
    </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Gender</label>
                                                        <div className="col-sm-9">
                                                            <select name="gender" className="form-control" required="" value={selectedGender} onChange={handleGenderChange}>
                                                                <option value="">Select Gender</option>
                                                                <option value="Male">Male</option>
                                                                <option value="Female">Female</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Marital Status</label>
                                                        <div className="col-sm-9">
                                                            <select name="marital_status" className="form-control" required="" value={selectedStatus} onChange={handleStatusChange}>
                                                                <option value="">Select marital status
                                                                </option>
                                                                <option value="Single">Single</option>
                                                                <option value="Married">Married</option>
                                                                <option value="Divorce">Divorce</option>
                                                                <option value="Widow">Widow</option>
                                                            </select>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Phone Number</label>
                                                        <div className="col-sm-9">
                                                            <input className="form-control" required="" type="text" value={phone} onChange={(e) => setPhone(e.target.value)} name="phone_number" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Email</label>
                                                        <div className="col-sm-9">
                                                            <input className="form-control" required="" type="email" value={email} onChange={(e) => setEmail(e.target.value)} name="email" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Staff ID</label>
                                                        <div className="col-sm-9">
                                                            <input className="form-control" required="" type="text" value={staffId} onChange={(e) => setStaffId(e.target.value)} name="staff_id" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">RSA Number</label>
                                                        <div className="col-sm-9">
                                                            <input className="form-control" required="" type="text" value={rsa} onChange={(e) => setRsa(e.target.value)} name="rsa_number" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Staff Image</label>
                                                        <div className="col-sm-9">
                                                            <input className="form-control" accept='.jpg, .jpeg, .png' type="file" onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>

                                            <h4>Section B:</h4>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Qualification</label>
                                                        <div className="col-sm-9">

                                                            <select className="form-control" required="" name="qualification" value={selectedCertificate} onChange={handleCertificateChange}>
                                                                <option value="First School Leaving Certificate">First School Leaving
                                                                    Certificate</option>
                                                                <option value="ND">ND</option>
                                                                <option value="NCE">NCE</option>
                                                                <option value="HND">HND</option>
                                                                <option value="BSc">BSc</option>
                                                                <option value="B.ed">B.ed</option>
                                                                <option value="BSc.ed">BSc.ed</option>
                                                                <option value="M.ed">M.ed</option>
                                                                <option value="MSc">MSc</option>
                                                                <option value="phd">PhD</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Step</label>
                                                        <div className="col-sm-9">
                                                            <select name="step" className="form-control" value={selectedStep} onChange={handleStepChange} >
                                                                <option value="">Select Step</option>
                                                                {tableData.map((item) => (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.description}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group row">
                                                        <label for="example-text-input" class="col-sm-3 col-form-label font-weight-400">Grade</label>
                                                        <div class="col-sm-9">
                                                            <select name="grade" class="form-control" value={selectedGrade} onChange={handleGradeChange}>
                                                                <option value="">Select Grade</option>
                                                                {tableData1.map((item) => (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.description}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group row">
                                                        <label for="example-text-input" class="col-sm-3 col-form-label font-weight-400">Level</label>
                                                        <div class="col-sm-9">
                                                            <select name="level" class="form-control" required="" value={selectedLevel} onChange={handleLevelChange}>
                                                                <option value="">Select Level</option>
                                                                {tableData2.map((item) => (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.description}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group row">
                                                        <label for="example-text-input" class="col-sm-3 col-form-label font-weight-400">Department</label>
                                                        <div class="col-sm-9">

                                                            <select name="dept_id" class="form-control" required="" value={selectedDepartment} onChange={handleDepartmentChange}>
                                                                <option value="">Select Department</option>
                                                                {tableData3.map((item) => (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Tax Identification Number</label>
                                                        <div className="col-sm-9">
                                                            <input className="form-control" required="" type="text" value={tin} onChange={(e) => setTin(e.target.value)} name="tin" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <h4>Section C</h4>
                                            <br />
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Country</label>
                                                        <div className="col-sm-9">
                                                            <select name="country" className="form-control" required="" id="country" value={selectedCountry} onChange={handleCountryChange}>
                                                                <option value="">
                                                                Select your Country
                                                                </option>
                                                                {countries.map((item) => (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">State of Origin</label>
                                                        <div className="col-sm-9">
                                                            <select
                                                                name="state"
                                                                value={selectedState}
                                                                onChange={handleStateChange}
                                                                className="form-control" required=""
                                                            >
                                                                <option value="">Select state of Origin</option>
                                                                {state.map((states) => (
                                                                    <option key={states.id_no} value={states.id_no}>
                                                                        {states.state}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                            
                                                    <div className="col-md-6">
                                                        <div className="form-group row">
                                                            <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Local Government</label>
                                                            <div className="col-sm-9">
                                                                <select
                                                                    name="lga"
                                                                    value={selectedLga}
                                                                    onChange={(e) => setSelectedLga(e.target.value)}
                                                                    className="form-control" required=""
                                                                >
                                                                    <option value="">Select LGA</option>
                                                                    {lga.map((lgas) => (
                                                                        <option key={lgas.id_no} value={lgas.id_no}>
                                                                            {lgas.local_govt}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                       
                                                        </div>
                                                    </div>

                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">City</label>
                                                        <div className="col-sm-9">
                                                            <input className="form-control" required="" type="text" value={city} onChange={(e) => setCity(e.target.value)} name="city" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" id="homeAdressNationalityDiv">
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Address</label>
                                                        <div className="col-sm-9">
                                                            <input className="form-control" required="" type="text" value={address} onChange={(e) => setAddress(e.target.value)} name="address" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Appointment
                                                            Date</label>
                                                        <div className="col-sm-9">
                                                            <input className="form-control" required="" type="date" onChange={handleEmployDateChange} value={selectedEmployDate} name="employment_date" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Retirement
                                                            Date</label>
                                                        <div className="col-sm-9">
                                                            <input class="form-control" required="" type="date" name="res_retir_date" value={selectedResignDate} onChange={handleResignDateChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-6">
                                                        <div classNames="form-group row">
                                                            <label for="example-text-input" class="col-sm-3 col-form-label font-weight-400">Account
                                                                Number</label>
                                                            <div class="col-sm-9">
                                                                <input className="form-control" required="" type="text" value={accountNo} onChange={(e) => setAccountNo(e.target.value)} name="account_number" />
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400"> Account
                                                            Number
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <input className="form-control" required="" type="text" value={accountNo} onChange={(e) => setAccountNo(e.target.value)} name="account_number" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400"> Name of Bank
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <input className="form-control" required="" type="text" value={bank} onChange={(e) => setBank(e.target.value)} name="account_bank" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Medical
                                                            Condition</label>
                                                        <div className="col-sm-9">
                                                            <input className="form-control" required="" type="text" value={medical} onChange={(e) => setMedical(e.target.value)} name="medical_condition" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>




                                            <div class="modal-footer">
                                                <Button variant='success' onClick={createStaff} >
                                                    {loading ? (
                                                        <>
                                                            <Spinner size='sm' />
                                                            <span style={{ marginLeft: '5px' }}>Creating Staff, Please wait...</span>
                                                        </>
                                                    ) : (
                                                        "Create Staff"
                                                    )}
                                                </Button>
                                                {/* <Button>Save Changes</Button> */}
                                                {/* <button type="submit" class="btn btn-success"><span id="loaderg" className="spinner-border spinner-border-sm me-2" role="status" style={{display:"none",}}></span>Save changes</button> */}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <InfoFooter />
        </div>
    )
}

export default CreateNewStaff