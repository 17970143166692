import React from 'react';
import { NavLink } from 'react-router-dom';

export const InfoFooter = () => {
    const currentYear = new Date().getFullYear();

    return (
        <div>
            <footer className="footer-content">
                <div className="footer-text d-flex align-items-center justify-content-between">
                    <div className="copy">© {currentYear} BSRV Professionals, Providing Payment Solutions for Nigerians</div>
                    <div className="credit">Designed by: <NavLink to="#">BrookesTechnologies</NavLink></div>
                </div>
            </footer>
        </div>
    );
}
